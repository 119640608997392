import { am } from './utils';

import httpClient from '@/utils/httpClient';

const GET_COLLECTION = am(`GET_LIST`);
const GET_DETAILS = am(`GET_LIST_ITEM`);
const UPDATE_INVENTION = am(`UPDATE_INVENTION`);

export const MILESTONE_UPDATE = am(`MILESTONE_UPDATE`);

const sortMilestones = (a, b) => {
  if (!a.id && !b.id) {
    return 0;
  }

  if (!b.id) {
    return 1;
  }

  if (!a.id) {
    return -1;
  }

  if (a.finished && b.finished) {
    return 0;
  }

  if (!a.finished) {
    return -1;
  }

  if (!b.finished) {
    return 1;
  }

  return 0;
};

const markNextTask = (invention, milestones, mode) => {
  if (!invention || !milestones?.length) {
    return;
  }

  const nextTask = (mode === 'My Next Task' ? invention.myNextTask : invention.nextTask) || invention.nextTask;

  if (nextTask) {
    milestones?.forEach(milestone => {
      milestone.tasks?.forEach(t => {
        if (t.id === nextTask.id) {
          t.isNext = true;
        } else {
          t.isNext = false;
        }
      });
    });
  }
};

const state = {
  total: 0,
  from: 0,
  data: [],
  isRequestPending: false,
  isRequestFailed: true,
  expanded: null,

  settings: {
    firstShow: (localStorage.getItem('MILESTONE_OVERVIEW_FIRST_SHOW') || 'true') === 'true',
    formTitle: 'Milestones Overview Settings',
    source: 'milestonesOverview',
    taskViewMode: {
      title: 'Next task mode',
      options: ['Next Task', 'My Next Task'],
      code: 'MILESTONE_OVERVIEW_NEXT_TASK_MODE',
      value: localStorage.getItem('MILESTONE_OVERVIEW_NEXT_TASK_MODE') || 'Next Task'
    },
    colorCoding: {
      title: 'Color coding',
      options: ['On', 'Off'],
      code: 'MILESTONE_OVERVIEW_SHOW_COLORS',
      value: localStorage.getItem('MILESTONE_OVERVIEW_SHOW_COLORS') || 'On'
    },
    displayMode: {
      title: 'Display mode',
      options: ['Standard', 'Compact', 'Compact + Notes'],
      code: 'MILESTONE_OVERVIEW_DISPLAY_MODE',
      value: localStorage.getItem('MILESTONE_OVERVIEW_DISPLAY_MODE') || 'Standard'
    }
  },

  details: {}
};
const getters = {
  milestonesByDetailsId: state => id => {
    return state.details[id].milestones;
  }
};

const mutations = {
  [GET_COLLECTION.STARTED](state, { initialize, refresh }) {
    if (!refresh && initialize) {
      state.isRequestPending = true;
    }
    state.isRequestFailed = false;
    if (initialize) {
      state.data = [];
    }
  },
  [GET_COLLECTION.COMPLETED](state, { data, total, refresh }) {
    state.isRequestPending = false;
    if (refresh) {
      state.data = data;
    } else {
      for (const item of data) {
        const index = state.data.findIndex(({ id }) => item.id === id);
        if (index > -1) {
          state.data[index] = item;
        } else {
          state.data.push(item);
        }
      }
    }

    state.total = total;
  },
  [GET_COLLECTION.FAILED](state) {
    state.isRequestPending = false;
    state.isRequestFailed = true;
  },
  [GET_DETAILS.STARTED](state, { id }) {
    state.details[id] = {
      isRequestPending: true,
      isRequestFailed: false,
      milestones: []
    };
  },
  [GET_DETAILS.COMPLETED](state, { id, milestones }) {
    if (!state.details[id]) {
      state.details[id] = {};
    }

    state.details[id].isRequestPending = false;
    state.details[id].isRequestFailed = false;
    state.details[id].milestones = milestones;

    const invention = state.data?.find(i => i.id === id);

    if (!invention) {
      return;
    }

    markNextTask(invention, state.details[invention.id]?.milestones, state.settings.taskViewMode.value);
  },
  [GET_DETAILS.FAILED](state, { id }) {
    if (!state.details[id]) {
      state.details[id] = {};
    }

    state.details[id].isRequestPending = false;
    state.details[id].isRequestFailed = true;
  },
  ['GET_DETAILS.RESET'](state) {
    state.details = {};
  },
  [MILESTONE_UPDATE.STARTED](state, { inventionId, milestoneId }) {
    const milestone = state.details[inventionId].milestones.find(m => m.workflow.milestoneId === milestoneId);
    milestone.isUpdating = true;
  },
  [MILESTONE_UPDATE.COMPLETED](state, { inventionId, milestoneId }) {
    const milestone = state.details[inventionId].milestones.find(m => m.workflow.milestoneId === milestoneId);
    milestone.isUpdating = false;
  },
  [UPDATE_INVENTION.STARTED](state, { inventionId }) {
    const invention = state.data?.find(i => i.id === inventionId);

    if (invention) {
      invention.updating = true;
    }
  },
  [UPDATE_INVENTION.COMPLETED](state, { newInvention }) {
    const invention = state.data?.find(i => i.id === newInvention.id);

    if (invention) {
      invention.nextTask = newInvention.nextTask;
      invention.myNextTask = newInvention.myNextTask;
      invention.nextMilestone = newInvention.nextMilestone;
      invention.title = newInvention.title;
      invention.references = newInvention.references;
      invention.updating = false;

      markNextTask(invention, state.details[invention.id]?.milestones, state.settings.taskViewMode.value);
    }
  },
  ['SETTING.CHANGED'](state, { setting, value }) {
    const changedSetting = state.settings[setting];

    if (!changedSetting) {
      return;
    }

    if (changedSetting.value !== undefined) {
      changedSetting.value = value;
      localStorage.setItem(changedSetting.code, value);
    } else {
      state.settings[setting] = value;
    }

    switch (setting) {
      case 'firstShow':
        localStorage.setItem('MILESTONE_OVERVIEW_FIRST_SHOW', false);
        break;
      case 'taskViewMode':
        const invention = state.data.find(i => i.id === state.expanded);

        if (!invention) {
          return;
        }
        markNextTask(invention, state.details[invention.id]?.milestones, state.settings.taskViewMode.value);
        break;
    }
  },
  [UPDATE_INVENTION.FAILED](state, { inventionId }) {
    const invention = state.data?.find(i => i.id === inventionId);
    if (invention) {
      invention.updating = false;
    }
  },
  'DETAILS.EXPAND'(state, id) {
    state.expanded = id;
  },
  taskCreated(state, item) {
    const task = item.metadata.task;
    if (!task) {
      return;
    }

    const milestones = state.details[item.inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === task.workflow.milestoneId);
    if (!milestone) {
      return;
    }
    const taskIndex = milestone.tasks.sort((a, b) => a.order - b.order).findIndex(t => t.id === task.id);
    if (taskIndex > -1) {
      return;
    }

    milestone.tasks.push(task);
  },
  taskFetched(state, { inventionId, task }) {
    const milestones = state.details[inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === task.workflow.milestoneId);

    if (!milestone) {
      return;
    }

    const taskIndex = milestone.tasks.findIndex(t => t.id === task.id);
    if (taskIndex > -1) {
      milestone.tasks.splice(taskIndex, 1, task);
    }
    milestone.tasks.sort((a, b) => a.order - b.order);

    markNextTask(
      state.data.find(i => i.id === inventionId),
      state.details[inventionId]?.milestones,
      state.settings.taskViewMode.value
    );
  },
  taskUpdated(state, task) {
    const milestones = state.details[task.invention.id]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === task.workflow.milestoneId);

    if (!milestone) {
      return;
    }
    const taskIndex = milestone.tasks.findIndex(t => t.id === task.id);
    if (taskIndex > -1) {
      milestone.tasks[taskIndex] = task;
    }

    if (state.data?.length) {
      const invention = state.data.find(c => c.nextTask?.id === task.id || c.myNextTask?.id === task.id);
      if (!invention) {
        return;
      }

      const taskToUpdate = invention.nextTask?.id === task.id ? invention.nextTask : invention.myNextTask;

      const { title, dueAt, assignees, notes, status } = task;
      taskToUpdate.title = title;
      taskToUpdate.dueAt = dueAt;
      taskToUpdate.assignees = assignees;
      taskToUpdate.notes = notes;
      taskToUpdate.status = status;

      markNextTask(invention, state.details[invention.id]?.milestones, state.settings.taskViewMode.value);
    }
  },
  milestoneUpdated(state, item) {
    const milestones = state.details[item.inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === item.metadata.id);

    if (!milestone) {
      return;
    }

    const { id, title, dueAt, assignees, clientDueDate, workflow } = item.metadata;
    milestone.id = id;
    milestone.title = title;
    milestone.dueAt = dueAt;
    milestone.clientDueDate = clientDueDate;
    milestone.assignees = assignees;
    milestone.workflow = workflow;

    if (state.data?.length) {
      const nextMilestone = state.data.find(c => c.nextMilestone?.id === milestone?.id)?.nextMilestone;
      if (!nextMilestone) {
        return;
      }
      nextMilestone.title = title;
      nextMilestone.dueAt = dueAt;
      nextMilestone.clientDueDate = clientDueDate;
      nextMilestone.assignees = assignees;
    }
  },
  milestoneCreated(state, item) {
    const milestones = state.details[item.inventionId]?.milestones;

    if (!milestones) {
      return;
    }

    const milestone = milestones.find(m => m.id === item.metadata.id);
    if (milestone) {
      return;
    }

    const { id, title, dueAt, assignees, clientDueDate, workflow } = item.metadata;

    const newItem = {
      id,
      dueAt,
      title,
      clientDueDate,
      assignees,
      expanded: true,
      finished: false,
      completed: false,
      deleted: false,
      isUpdating: true,
      tasks: [],
      workflow
    };
    state.details[item.inventionId].milestones.splice(1, 0, newItem);
  },
  milestoneFetched(state, item) {
    const milestones = state.details[item.inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === item.workflow?.milestoneId);
    if (milestone) {
      milestone.isUpdating = false;
      milestone.workflow = item.workflow;
      milestone.nextTask = item.nextTask;
    }
  },
  milestoneFinished(state, { inventionId, milestoneId }) {
    const milestones = state.details[inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.id === milestoneId);
    milestone.finished = true;
    milestone.completed = true;
    milestone.expanded = false;

    state.details[inventionId].milestones = state.details[inventionId].milestones.sort(sortMilestones);
  }
};
const actions = {
  expand({ commit }, id) {
    commit('DETAILS.EXPAND', id);
  },
  async updateInvention({ commit, state }, inventionId) {
    try {
      const invention = state.data?.find(i => i.id === inventionId);

      if (!invention) {
        return;
      }

      commit(UPDATE_INVENTION.STARTED, { inventionId });

      const newInvention = await httpClient.get(`/api/workflow/search/inventions/${inventionId}`);

      commit(UPDATE_INVENTION.COMPLETED, { newInvention });
    } catch (error) {
      commit(UPDATE_INVENTION.FAILED, inventionId);
    }
  },
  async getCollection({ commit }, { assignee, filter, skip, sort, size, refresh } = {}) {
    try {
      commit(GET_COLLECTION.STARTED, {
        initialize: typeof skip === 'undefined' && !refresh,
        refresh
      });

      const params = new URLSearchParams();
      if (skip) {
        params.set('skip', skip);
      }

      if (filter) {
        params.set('qs', filter);
      }

      if (assignee) {
        params.set('assignee', assignee);
      }

      if (sort) {
        params.set('sort', sort);
      }
      if (size) {
        params.set('size', size);
      } else {
        params.set('size', 40);
      }

      const response = await httpClient.get(`/api/workflow/search/milestones?` + params.toString());

      commit(GET_COLLECTION.COMPLETED, {
        data: response.data,
        total: response.total,
        refresh
      });
    } catch (e) {
      commit(GET_COLLECTION.FAILED);
      throw e;
    }
  },
  async getDetails({ commit }, { id, assignee }) {
    try {
      commit(GET_DETAILS.STARTED, {
        id
      });

      const params = new URLSearchParams();

      if (assignee) {
        params.set('assignee', assignee);
      }

      const response = await httpClient.get(`/api/workflow/search/inventions/${id}/milestones?${params.toString()}`);

      commit(GET_DETAILS.COMPLETED, {
        id,
        milestones: response
          .map(milestone => ({
            ...milestone,
            completed: milestone.finished || milestone.deleted,
            expanded: !(milestone.finished || milestone.deleted),
            tasks: Array.isArray(milestone.tasks) ? milestone.tasks : []
          }))
          .sort(sortMilestones)
      });
    } catch (e) {
      commit(GET_DETAILS.FAILED, { id });
      throw e;
    }
  },
  resetDetails({ commit }) {
    commit('GET_DETAILS.RESET');
  },
  settingChanged({ commit }, { setting, value }) {
    commit('SETTING.CHANGED', { setting, value });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
