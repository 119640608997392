import { am } from '../utils';

import httpClient from '@/utils/httpClient';

const GET_COLLECTION = am(`GET_LIST`);
const GET_DETAILS = am(`GET_LIST_ITEM`);

export const MILESTONE_UPDATE = am(`MILESTONE_UPDATE`);

const sortMilestones = (a, b) => {
  if (!a.workflow?.milestoneId && !b.workflow?.milestoneId) {
    return 0;
  }

  if (!b.workflow?.milestoneId) {
    return 1;
  }

  if (!a.workflow?.milestoneId) {
    return -1;
  }

  if (a.finished && b.finished) {
    return 0;
  }

  if (!a.finished) {
    return -1;
  }

  if (!b.finished) {
    return 1;
  }

  return 0;
};

const state = {
  total: 0,
  from: 0,
  data: [],
  isRequestPending: false,
  isRequestFailed: true,

  details: {}
};
const getters = {
  milestonesByDetailsId: state => id => {
    return state.details[id].milestones;
  }
};

const mutations = {
  [GET_COLLECTION.STARTED](state, { initialize, refresh }) {
    if (!refresh && initialize) {
      state.isRequestPending = true;
    }
    state.isRequestFailed = false;
    if (initialize) {
      state.data = [];
    }
  },
  [GET_COLLECTION.COMPLETED](state, { data, total, refresh }) {
    state.isRequestPending = false;
    if (refresh) {
      state.data = data;
    } else {
      for (const item of data) {
        const index = state.data.findIndex(({ id }) => item.id === id);
        if (index > -1) {
          state.data[index] = item;
        } else {
          state.data.push(item);
        }
      }
    }

    state.total = total;
  },
  [GET_COLLECTION.FAILED](state) {
    state.isRequestPending = false;
    state.isRequestFailed = true;
  },
  [GET_DETAILS.STARTED](state, { id }) {
    state.details[id] = {
      isRequestPending: true,
      isRequestFailed: false,
      milestones: []
    };
  },
  [GET_DETAILS.COMPLETED](state, { id, milestones }) {
    if (!state.details[id]) {
      state.details[id] = {};
    }

    state.details[id].isRequestPending = false;
    state.details[id].isRequestFailed = false;
    state.details[id].milestones = milestones;
  },
  [GET_DETAILS.FAILED](state, { id }) {
    if (!state.details[id]) {
      state.details[id] = {};
    }

    state.details[id].isRequestPending = false;
    state.details[id].isRequestFailed = true;
  },
  ['GET_DETAILS.RESET'](state) {
    state.details = [];
  },
  [MILESTONE_UPDATE.STARTED](state, { inventionId, milestoneId }) {
    const milestone = state.details[inventionId].milestones.find(m => m.workflow.milestoneId === milestoneId);
    milestone.isUpdating = true;
  },
  [MILESTONE_UPDATE.COMPLETED](state, { inventionId, milestoneId }) {
    const milestone = state.details[inventionId].milestones.find(m => m.workflow.milestoneId === milestoneId);
    milestone.isUpdating = false;
  },
  taskCreated(state, item) {
    const task = item.metadata.task;
    if (!task) {
      return;
    }

    const milestones = state.details[item.inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.workflow.milestoneId === task.workflow.milestoneId);
    if (!milestone) {
      return;
    }
    const taskIndex = milestone.tasks.sort((a, b) => a.order - b.order).findIndex(t => t.id === task.id);
    if (taskIndex > -1) {
      return;
    }

    milestone.tasks.push(task);
  },
  taskFetched(state, { inventionId, task }) {
    const milestones = state.details[inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.workflow.milestoneId === task.workflow.milestoneId);
    const taskIndex = milestone.tasks.findIndex(t => t.id === task.id);
    if (taskIndex > -1) {
      milestone.tasks.splice(taskIndex, 1, task);
    }
    milestone.tasks.sort((a, b) => a.order - b.order);
  },
  taskUpdated(state, item) {
    const milestones = state.details[item.inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.workflow.milestoneId === item.metadata.task.workflow.milestoneId);
    const taskIndex = milestone.tasks.findIndex(t => t.id === item.metadata.task.id);
    if (taskIndex > -1) {
      milestone.tasks[taskIndex] = item.metadata.task;
    }
  },

  milestoneUpdated(state, item) {
    const milestones = state.details[item.inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.workflow?.milestoneId === item.metadata.id);
    const { title, dueAt, assignees, clientDueDate } = item.metadata;
    milestone.title = title;
    milestone.dueAt = dueAt;
    milestone.clientDueDate = clientDueDate;
    milestone.assignees = assignees;
    milestone.workflow.milestoneAssignees = assignees;
    milestone.workflow.milestoneTitle = title;
    milestone.workflow.milestoneDueAt = dueAt;
    milestone.workflow.milestoneClientDueDate = clientDueDate;
  },
  milestoneCreated(state, item) {
    const milestones = state.details[item.inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.workflow?.milestoneId === item.metadata.id);
    if (milestone) {
      return;
    }
    const newItem = {
      id: null,
      completed: false,
      deleted: false,
      dueAt: item.metadata.dueAt,
      clientDueDate: item.metadata.clientDueDate,
      expanded: true,
      finished: false,
      roadmap: [],
      tasks: [],
      status: 'to do',
      title: item.metadata.title,
      isUpdating: true,
      workflow: {
        id: null,
        milestoneAssignees: item.metadata.assignees,
        milestoneDeleted: false,
        milestoneDueAt: item.metadata.dueAt,
        milestoneClientDueDate: item.metadata.clientDueDate,
        milestoneId: item.metadata.id,
        milestoneTemplateId: item.metadata.workflow.template.id,
        milestoneTitle: item.metadata.title
      }
    };
    state.details[item.inventionId].milestones.splice(1, 0, newItem);
  },
  milestoneFetched(state, milestone) {
    const milestones = state.details[milestone.workflow?.milestoneId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const target = milestones.find(m => m.workflow?.milestoneId === milestone.workflow.milestoneId);
    if (target) {
      target.isUpdating = false;
      target.workflow = milestone.workflow;
      target.roadmap = milestone.roadmap;
      target.nextTask = milestone.nextTask;
    }
  },
  milestoneFinished(state, { inventionId, milestoneId }) {
    const milestones = state.details[inventionId]?.milestones;

    if (!milestones?.length) {
      return;
    }

    const milestone = milestones.find(m => m.workflow.milestoneId === milestoneId);
    milestone.finished = true;
    milestone.completed = true;
    milestone.expanded = false;

    state.details[inventionId].milestones = state.details[inventionId].milestones.sort(sortMilestones);
  }
};
const actions = {
  async getCollection({ commit }, { qs, skip, sort, size, refresh } = {}) {
    try {
      commit(GET_COLLECTION.STARTED, {
        initialize: typeof skip === 'undefined' && !refresh,
        refresh
      });

      const params = new URLSearchParams();
      if (skip) {
        params.set('skip', skip);
      }

      if (qs) {
        params.set('qs', qs);
      }

      if (sort) {
        params.set('sort', sort);
      }
      if (size) {
        params.set('size', size);
      } else {
        params.set('size', 40);
      }

      const response = await httpClient.get(`/api/workflow/milestones/?` + params.toString());

      commit(GET_COLLECTION.COMPLETED, {
        data: response.data.map(item => ({
          id: item.inventionId,
          references: item.references,
          title: item.name,
          nextTaskTitle: item.nextTaskTitle,
          status: item.status,
          roadmap: item.roadmap,
          dueAt: item.dueAt,
          clientDueDate: item.workflow.milestoneClientDueDate,
          milestoneDueAt: item.workflow.milestoneDueAt,
          nextTask: item.nextTask,
          nextCriticalTask: item.nextCriticalTask
        })),
        total: response.total,
        refresh
      });
    } catch (e) {
      commit(GET_COLLECTION.FAILED);
      throw e;
    }
  },
  async getDetails({ commit }, { id }) {
    try {
      commit(GET_DETAILS.STARTED, {
        id
      });

      const response = await httpClient.get(`/api/workflow/milestones/inventions/${id}`);

      commit(GET_DETAILS.COMPLETED, {
        id,
        milestones: response
          .map(milestone => ({
            id: milestone.name,
            title: milestone.name,
            status: milestone.status,
            dueAt: milestone.dueAt,
            clientDueDate: milestone.workflow.milestoneClientDueDate,
            completed: milestone.completed,
            deleted: milestone.deleted,
            finished: milestone.completed || milestone.delete,
            expanded: !(milestone.completed || milestone.deleted),
            roadmap: milestone.roadmap,
            tasks: Array.isArray(milestone.tasks) ? milestone.tasks : [],
            workflow: milestone.workflow
          }))
          .sort(sortMilestones)
      });
    } catch (e) {
      commit(GET_DETAILS.FAILED, { id });
      throw e;
    }
  },
  resetDetails({ commit }) {
    commit('GET_DETAILS.RESET');
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
