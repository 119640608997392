import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';

const GET_MILESTONE_INSTANCE = am(`GET_MILESTONE_INSTANCE`);
const GET_MILESTONE_TEMPLATES = am(`GET_MILESTONE_TEMPLATES`);

export default {
  namespaced: true,
  ...factory({
    state: {
      getMilestoneInstanceRequestPending: false,
      milestoneInstance: null,
      templates: {}
    },
    getters: {
      getMilestoneTemplates: state => id => {
        if (!id) {
          return null;
        }

        return state.templates[id]?.template;
      }
    },
    mutations: {
      [GET_MILESTONE_INSTANCE.STARTED](state) {
        state.getMilestoneInstanceRequestPending = true;
        state.milestoneInstance = null;
      },
      [GET_MILESTONE_INSTANCE.COMPLETED](state, milestone) {
        state.milestoneInstance = milestone;
        state.getMilestoneInstanceRequestPending = false;
      },
      [GET_MILESTONE_TEMPLATES.STARTED](state, templateIds = []) {
        templateIds.forEach(id => {
          if (state.templates[id]) {
            return;
          }
          state.templates[id] = {
            isRequestPending: false,
            isRequestFailed: false,
            template: null,
            name: null
          };
        });
      },
      [GET_MILESTONE_TEMPLATES.COMPLETED](state, { workflowId, template, workflow }) {
        if (!state.templates[workflowId]) {
          state.templates[workflowId] = {};
        }

        state.templates[workflowId].template = template;
        state.templates[workflowId].isRequestPending = false;
        state.templates[workflowId].isRequestFailed = false;
        state.templates[workflowId].name = workflow.title;
      },
      [GET_MILESTONE_TEMPLATES.FAILED](state, { workflowId }) {
        state.templates[workflowId].template = null;
        state.templates[workflowId].isRequestPending = false;
        state.templates[workflowId].isRequestFailed = true;
      }
    },
    actions: {
      async getMilestoneInstance({ commit }, id) {
        commit(GET_MILESTONE_INSTANCE.STARTED);
        const milestone = await httpClient.get(`/api/workflow/milestones/${id}`);
        commit(GET_MILESTONE_INSTANCE.COMPLETED, milestone);
      },
      async updateMilestoneInstance({ commit }, id) {
        commit(GET_MILESTONE_INSTANCE.STARTED);
        const instance = await httpClient.get(`/api/workflow/milestones/${id}`);
        commit(GET_MILESTONE_INSTANCE.COMPLETED, instance);
      },
      async bulkGetTemplates({ commit, state }, workflowIds) {
        commit(GET_MILESTONE_TEMPLATES.STARTED, workflowIds);

        const promises = workflowIds.map(workflowId => {
          if (state.templates[workflowId].template) {
            return Promise.resolve();
          }
          return Promise.all([
            httpClient.get(`/api/workflow/v2/workflows/${workflowId}/milestones`),
            httpClient.get(`/api/workflow/v2/workflows/${workflowId}`)
          ])
            .then(([template, workflow]) => {
              commit(GET_MILESTONE_TEMPLATES.COMPLETED, { workflowId, template: template.filter(m => !m.isDraft), workflow });
            })
            .catch(() => commit(GET_MILESTONE_TEMPLATES.FAILED, { workflowId }));
        });
        await Promise.all(promises);
      }
    }
  })('milestones', {
    async getCollection({ references, workflowId }) {
      let milestonesTemplates;
      if (workflowId) {
        milestonesTemplates = await httpClient.get(`/api/workflow/v2/workflows/${workflowId}/milestones`);

        if (milestonesTemplates?.length) {
          return milestonesTemplates?.filter(m => !m.isDraft);
        }
      }

      let workflow;
      try {
        workflow = await httpClient.get(`/api/workflow/v2/workflows/by/references?${references.map(r => 'references=' + r).join('&')}`);
      } catch (e) {}
      if (!workflow) {
        workflow = await httpClient.get(`/api/workflow/v2/workflows/by/default`);
      }
      milestonesTemplates = await httpClient.get(`/api/workflow/v2/workflows/${workflow.id}/milestones`);
      return milestonesTemplates?.filter(m => !m.isDraft);
    },
    async create(payload) {
      return httpClient.post(`/api/workflow/milestones`, payload);
    },
    async getById({ workflowId, milestoneId }) {
      const collection = await httpClient.get(`/api/workflow/v2/workflows/${workflowId}/milestones`);
      const milestone = collection.filter(t => !t.isDraft)?.find(milestone => milestone.id === milestoneId);
      return milestone;
    },
    async delete(id) {
      return httpClient.delete(`/api/workflow/milestones/${id}`);
    },
    async update(id, payload) {
      return httpClient.put(`/api/workflow/milestones/${id}`, payload);
    }
  })
};
