<template>
  <header>
    <div class="left">
      <router-link v-if="logo" to="/">
        <img alt="Patent Hub logo" src="./../assets/logo.svg" width="64" height="32" />
      </router-link>
      <search-input v-if="isAuthenticated" />
    </div>
    <div v-if="isAuthenticated" class="right">
      <p-button variant="icon" class="icon" :class="{ toggle: panelShown }" @click="goToTasks">
        <p-icon class="icon" :class="{ toggle: $route.path.endsWith('/reports/tasks') }" name="clock"></p-icon>
      </p-button>
      <p-button v-if="settingVisible" variant="icon" class="icon" :class="{ toggle: panelShown }" @click="toggleSettings"
        ><p-icon name="cog" class="icon" :class="{ toggle: panelShown }"
      /></p-button>
      <div v-if="isAuthenticating" class="loading">
        <p-icon name="loading" spin size="md"></p-icon>
      </div>
      <div v-else-if="initials?.length" class="full-name-area" @click="toggleMenu">
        <div class="full-name" :title="fullName">
          <span class="circle" :class="{ toggle: menuVisible }">{{ initials }}</span>
        </div>
        <div v-if="menuVisible" class="user-profile-menu">
          <div class="menu-row" title="Your profile" @click="showProfile = true">
            <div>
              <p-icon name="account-cog" class="menu-icon" size="smx"></p-icon>
              <span>Profile</span>
            </div>
          </div>
          <div class="menu-row" title="Change theme" @click="toggleTheme">
            <div>
              <p-icon class="menu-icon" name="invert-colors" size="smx" />
              <span>Theme</span>
            </div>
          </div>

          <div class="menu-row" title="Leave your feedback" @click="goToFeedback">
            <div>
              <p-icon name="help" class="menu-icon" size="smx"></p-icon>
              <span>Feedback</span>
            </div>
          </div>
          <div class="menu-row" title="Logout from Patent Hub" @click="logout">
            <div>
              <p-icon name="logout" class="menu-icon" size="smx"></p-icon>
              <span>Logout</span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <profile-modal v-if="showProfile" @close="profileClose" />
      </div>
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import httpClient from '@/utils/httpClient';
import { lw } from '@/utils/lw';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import Profile from '@/components/Profile';
import SearchInput from './SearchInput.vue';
export default {
  components: {
    'p-button': Button,
    'p-icon': Icon,
    'profile-modal': Profile,
    SearchInput
  },
  props: {
    logo: {
      type: Boolean,
      default: true
    },
    search: {
      type: Boolean,
      default: true
    },
    help: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      theme: lw.get('HUB_THEME') || 'dark',
      menuVisible: false,
      showProfile: false
    };
  },
  computed: {
    ...mapState({
      isAuthenticated: s => s.identity.isAuthenticated,
      isAuthenticating: s => s.identity.isRequestPending,
      settingVisible: s => s.settings.visible,
      panelShown: s => s.settings.panelShown
    }),
    ...mapGetters({
      fullName: 'identity/fullName',
      initials: 'identity/initials'
    })
  },
  watch: {
    theme: {
      handler() {
        if (this.theme === 'light') {
          document.body.classList.add('theme-light');
        } else {
          document.body.classList.remove('theme-light');
        }
      },
      immediate: true
    }
  },
  methods: {
    goToTasks() {
      this.$router.push(`/reports/tasks`);
    },
    toggleSettings() {
      this.$store.dispatch('settings/togglePanel');
    },
    toggleMenu() {
      if (this.menuVisible) {
        this.menuVisible = false;
        document.removeEventListener('click', this.toggleMenu);
      } else {
        this.menuVisible = true;
        setTimeout(() => document.addEventListener('click', this.toggleMenu), 0);
      }
    },
    profileClose() {
      this.showProfile = false;
    },
    async goToFeedback() {
      const lock = this.$lock();
      const url = await httpClient.get('/api/feedback/canny/redirectUrl');
      window.open(url, '_blank');
      lock.release();
    },
    async logout() {
      this.$store.dispatch('identity/logout');
      window.location.reload();
    },
    toggleTheme() {
      if (this.theme === 'dark') {
        this.theme = 'light';
      } else {
        this.theme = 'dark';
      }
      lw.set('HUB_THEME', this.theme);
    }
  }
};
</script>

<style scoped lang="scss">
header {
  --theme-header-surface: #181818;
  --theme-header-on-surface: #fff;

  display: grid;
  grid-template-columns: minmax(0, 1fr) 350px;
  grid-template-rows: 50px;
  align-items: center;
  color: var(--theme-header-on-surface);
  background-color: var(--theme-header-surface);
  padding: 0 0.75rem;
  grid-gap: 10px;

  a {
    text-decoration: none;
    cursor: pointer;
    display: grid;
    grid-template-columns: max-content max-content;
    grid-gap: 10px;
    align-items: center;
    font-size: 1.5rem;
    font-family: 'Roboto Mono', monospace;
    font-weight: 700;

    &:visited,
    &:active,
    &:focus {
      text-decoration: none;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      height: 32px;
      width: 64px;
    }

    label {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > * {
      cursor: pointer !important;
    }
  }

  .left {
    display: grid;
    grid-template-columns: max-content 1fr 1fr;
    gap: 5px;
    align-items: center;

    .breadcrumb {
      color: #d2c023;
      font-size: 1.5rem;
      font-family: 'Roboto Mono', monospace;
      text-transform: capitalize;
      font-weight: 700;
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    padding: 0 0.5rem;

    .loading {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
    }

    router-link {
      padding: 0;
      margin: 0;
    }

    .icon {
      color: var(--theme-header-on-surface);

      &:hover {
        color: var(--theme-primary);
      }

      &.toggle {
        color: var(--theme-primary);
      }
    }

    .full-name-area {
      margin-left: 5px;
      display: flex;
      cursor: pointer;
      align-content: center;
      user-select: none;

      .full-name {
        display: flex;
        font-weight: 600;
        color: var(--theme-header-surface);

        .circle {
          font-size: 0.8rem;
          background-color: var(--theme-header-on-surface);
          width: 30px;
          padding: 1px;
          height: 30px;
          display: flex;
          border-radius: 50%;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: var(--theme-secondary);
          }

          &.toggle {
            background-color: var(--theme-secondary);
          }
        }
      }

      .user-profile-menu {
        display: block;
        position: absolute;
        top: 51px;
        right: 1px;
        padding: 5px;
        background: var(--theme-header-surface);
        color: var(--theme-header-on-surface);
        border-radius: 0 0 2px 2px;
        z-index: 1000;
        min-width: 125px;

        .menu-row {
          .menu-icon {
            color: var(--theme-header-on-surface);
          }

          &:hover {
            background-color: var(--theme-primary);
          }

          span {
            text-transform: uppercase;
            font-size: 0.9rem;
            letter-spacing: 0.04rem;
            font-weight: 500;
          }

          div {
            border-radius: 1px;
            padding: 7px 5px;
            cursor: pointer;
            font-size: 1rem;
            justify-items: flex-start;
            align-items: center;

            display: grid;
            grid-template-columns: 25px 1fr;
          }
        }
      }
    }
  }
}
</style>
