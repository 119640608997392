import { factory, am } from './utils';

import httpClient from '@/utils/httpClient';
const GET_STEP_FORM = am(`GET_STEP_FORM`);

export default {
  namespaced: true,
  ...factory({
    state: {
      formRequestPending: false,
      form: null
    },
    mutations: {
      [GET_STEP_FORM.STARTED](state) {
        this.state.formRequestPending = true;
      },
      [GET_STEP_FORM.COMPLETED](state, form) {
        state.form = form;
        this.state.formRequestPending = false;
      },
      [GET_STEP_FORM.FAILED]() {
        this.state.formRequestPending = false;
      }
    },
    actions: {
      async getForm({ commit }, payload) {
        commit(GET_STEP_FORM.STARTED);
        const form = await httpClient.post(`/api/workflow/execute/bind`, payload);
        commit(GET_STEP_FORM.COMPLETED, form);
        return form;
      },
      async updateStepInstructions({ rootState }, { workflowId, milestoneId, stepId, instructions }) {
        const workflowTemplate = rootState.milestones.templates[workflowId];
        if (!workflowTemplate) {
          throw 'workflow template not found';
        }
        const milestone = workflowTemplate.template.find(m => m.id === milestoneId);
        if (!milestone) {
          throw 'milestone template not found';
        }
        const stepIndex = milestone.steps.findIndex(s => s.id === stepId);
        if (stepIndex < 0) {
          throw 'Step not found';
        }
        const payload = { ...milestone };

        payload.steps[stepIndex].options.instructions = instructions;

        return httpClient.put(`/api/workflow/v2/workflows/${workflowId}/milestones/${milestoneId}`, payload);
      }
    }
  })('steps', {
    async getCollection() {
      throw new Error('Not implemented.');
    },
    async getById({ workflowId, milestoneId, stepId }) {
      try {
        return await httpClient.get(`/api/workflow/v2/workflows/${workflowId}/milestones/${milestoneId}/steps/${stepId}`);
      } catch (error) {
        return null;
      }
    },
    async delete() {
      throw new Error('Not implemented.');
    },
    async update() {
      throw new Error('Not implemented.');
    }
  })
};
