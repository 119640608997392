<template>
  <p-multiselect
    :key="value"
    :value="prepairedValue"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
    :search-change-callback="suggestAsync"
    :taggable="!single"
    :can-clear="false"
    :object="shorthand"
    :filter-results="true"
    :select-on-blur="selectOnBlur"
    :no-options-text="'Search for people'"
    @change="change"
    @update:value="change"
  />
</template>

<script>
import httpClient from '@/utils/httpClient';

import Multiselect from '@/components/common/Multiselect';
export default {
  components: {
    'p-multiselect': Multiselect
  },
  props: {
    label: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    shorthand: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    },
    mapper: {
      type: Function,
      default: undefined
    },
    selectOnBlur: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'update:value'],
  computed: {
    prepairedValue() {
      if (this.shorthand) {
        return Array.isArray(this.value)
          ? this.value.map(value => (this.mapper ? this.mapper(value) : { label: value.split('@')[0], value }))
          : this.mapper
          ? this.mapper(this.value)
          : {
              label: this.value.split('@')[0],
              value: this.value
            };
      }
      return this.value;
    }
  },
  methods: {
    change(value) {
      let prepairedValue;

      if (this.shorthand) {
        prepairedValue = Array.isArray(value) ? value.map(v => v.value) : value?.value;
      } else {
        prepairedValue = value;
      }

      this.$emit('change', prepairedValue);
      this.$emit('update:value', prepairedValue);
    },
    async suggestAsync(q) {
      if (q && q.length) {
        const options = await httpClient.get(`/api/suggest/assignees?q=${q}`);
        if (this.shorthand) {
          return options.map(value => (this.mapper ? this.mapper(value) : { label: value.split('@')[0], value }));
        }
        return options;
      }

      return [];
    }
  }
};
</script>
