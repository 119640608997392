export const state = {
  visible: false,
  panelShown: false,
  current: null
};

export const mutations = {
  'SETTINGS.BUTTON.SHOW'(state, { setting }) {
    state.current = setting;
    state.visible = true;
    state.panelShown = setting.firstShow;
  },
  'SETTINGS.BUTTON.HIDE'(state) {
    state.visible = false;
    state.panelShown = false;
  },
  'SETTINGS.PANEL.TOGGLE'(state) {
    state.panelShown = !state.panelShown;
  }
};

export const actions = {
  initialize({ commit }, setting) {
    commit('SETTINGS.BUTTON.SHOW', { setting });
  },
  reset({ commit }) {
    commit('SETTINGS.BUTTON.HIDE');
  },
  togglePanel({ commit }) {
    commit('SETTINGS.PANEL.TOGGLE');
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
